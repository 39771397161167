<template>
    <div><g-loading /></div>
</template>

<script>
    import Vue from 'vue';
    
    export default {
        props: ['token'],
        data: function () {
            return {
                form: {},
                errors: {}
            };
        },
        methods: {
            use: function () {
                localStorage.removeItem('token');
                sessionStorage.setItem('token', this.token);
                let authorization = btoa(`${this.token}:`);
                Vue.prototype.$service.defaults.headers.common['Authorization'] = `Basic ${authorization}`;
                location.href = '/';
            }
        },
        mounted() {
            this.use();
        },
    };
</script>